import React, { useState, useCallback } from 'react';

import EditOutlined from '@ant-design/icons/EditOutlined';
import { Button, Tooltip } from 'antd';
import { CustomButtonAntd } from 'components/Shared/CustomButtonAntd/style';
import { useHistory } from 'react-router-dom';

import * as S from './styleOptionsButton';

const BasicDataOptionsButton = ({ isLive, eventId, shouldDisableEdit, ...props }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpenOptionMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOptionMenu = (onClick: () => void) => {
    onClick();
    handleClose();
  };

  const handleGoEdit = useCallback(() => {
    if (isLive) {
      return history.push(`/my-events/${eventId}/edit`);
    }

    return history.push(`/event/edit/${eventId}`);
  }, [history, isLive, eventId]);

  return (
    <S.Container className={props.className}>
      <div className='event-header-actions'>
        <CustomButtonAntd id='event-options-btn' size='large' onClick={handleOpenOptionMenu}>
          Opções
        </CustomButtonAntd>
        <Tooltip
          trigger='hover'
          title={
            shouldDisableEdit &&
            'Alterações em eventos passados não são permitidas para garantir a integridade e a transparência do evento.'
          }
          color='#ffbc00'
        >
          <Button type='primary' id='event-edit-btn' size='large' onClick={handleGoEdit} disabled={shouldDisableEdit}>
            <EditOutlined />
            Editar
          </Button>
        </Tooltip>
      </div>
      <S.OptionMenu id='simple-menu' anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
        {props.optionsMenu?.map((option, index) => {
          return (
            <S.OptionMenuItem key={index} onClick={() => handleClickOptionMenu(option.onClick)}>
              {option.icon} {option.label}
            </S.OptionMenuItem>
          );
        })}
      </S.OptionMenu>
    </S.Container>
  );
};

export default BasicDataOptionsButton;
