import * as Rx from 'rxjs';

import apiService, { ApiService } from './api';

export class LotService {
  constructor(private apiService: ApiService) {
    this.apiService = apiService;
  }

  public delete(id: string): Rx.Observable<void> {
    return this.apiService.delete(`/lot/delete/${id}`);
  }

  public desactivate(id: string): Rx.Observable<void> {
    return this.apiService.post(`/lot/deactivate/${id}`, {});
  }
}

const lotService = new LotService(apiService);
export default lotService;
