import React, { Reducer, useCallback, useMemo, useReducer, useEffect } from 'react';

import { ArrowRightOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import { useEventDetail } from 'components/Pages/Admin/TabsMyEvents/context/EventDetail';
import CustomLoader from 'components/Shared/CustomLoader';
import Toast from 'components/Shared/Toast';
import Tour from 'components/Shared/Tour';
import { getTargetElement } from 'components/Shared/Tour/helpers';
import { TourSteps } from 'components/Shared/Tour/types';
import { useCustomObservable } from 'hooks';
import useTabTour from 'hooks/useTabTour';
import { IOffer } from 'interfaces/offer';
import ReactPlaceholder from 'react-placeholder/lib';
import { useParams } from 'react-router-dom';
import { DeleteOffer, AddOffers } from 'services/offers/types';

import PageTitle from '../components/PageTitle';
import { ModalDelete, ModalLink, ModalOffers, OffersTickets } from './components';
import OffersContext from './context';
import customOffersService from './service';
import * as S from './styles';
import { PageParams, StateProps, ActionProps, ActionTypes } from './types';

const initialState: StateProps = {
  offers: [],
  openModalOffer: false,
  generateLinkOffer: '',
  loadingLinkOfferId: '',
  loadingPage: false,
  deleteOfferIds: { eventId: '', offerPackId: '' }
};

const reducer: Reducer<StateProps, ActionProps> = (state, action) => {
  switch (action.type) {
    case ActionTypes.offers:
      return { ...state, offers: action.value as IOffer[], loadingPage: false };

    case ActionTypes.openModalOffer:
      return { ...state, openModalOffer: action.value as boolean };

    case ActionTypes.generateLinkOffer:
      return { ...state, generateLinkOffer: action.value as string, loadingLinkOfferId: '' };

    case ActionTypes.loadingLinkOfferId:
      return { ...state, loadingLinkOfferId: action.value as string };

    case ActionTypes.loadingPage:
      return { ...state, loadingPage: action.value as boolean };

    case ActionTypes.offersDelete:
      return { ...state, deleteOfferIds: action.value as DeleteOffer };

    case ActionTypes.offersDeleteSuccess:
      return { ...state, deleteOfferIds: initialState.deleteOfferIds, loadingPage: false };
  }
};

const steps: TourSteps[] = [
  {
    title: 'Ofertas personalizadas',
    description:
      'Faça uma oferta personalizada para seu cliente, adicione quantos ingressos desejar e determine o valor da oferta.',
    target: getTargetElement("div[data-node-key='offers']")
  },
  {
    title: 'Ofertas personalizadas',
    description:
      'Após criação da oferta, compartilhe o link para seu cliente e, após a compra, o link se tornará inválido para garantir exclusividade no desconto.',
    target: getTargetElement('div.custom-offers-button button'),
    placement: 'bottomRight',
    isLastStep: true
  }
];

const CustomOffers = () => {
  const [{ offers, openModalOffer, generateLinkOffer, loadingLinkOfferId, loadingPage, deleteOfferIds }, dispatch] =
    useReducer(reducer, {
      ...initialState
    });

  const { id: eventId } = useParams<PageParams>();
  const { showTour, openTour, closeTour } = useTabTour('offers');

  const { data: eventData, loading: eventLoading } = useEventDetail();

  const { value: customOffersData, loading: customOffersLoading } = useCustomObservable<IOffer[], string, string>(
    () => customOffersService.getCustomOffers(eventId),
    eventId
  );

  useEffect(() => {
    if (customOffersData) {
      dispatch({ type: ActionTypes.offers, value: customOffersData });
    }
  }, [customOffersData, customOffersLoading]);

  const isShown = useMemo(() => !eventLoading && !customOffersLoading, [eventLoading, customOffersLoading]);

  const handleUpdateList = useCallback(() => {
    dispatch({ type: ActionTypes.loadingPage, value: true });
    customOffersService.getCustomOffers(eventId).subscribe({
      next: customOffersData => {
        dispatch({ type: ActionTypes.offers, value: customOffersData });
      },
      error: () => {
        Toast.show('Erro ao carregar lista.');
      }
    });
  }, [eventId]);

  const handleSubmitForm = (formData: AddOffers) => {
    customOffersService.addCustomOffers(formData).subscribe({
      next: () => {
        Toast.show('Salvo com sucesso');
        handleOpenModal();
        handleUpdateList();
      },
      error: ({ data: error }) => {
        Toast.error(error.details);
      }
    });
  };

  const handleCleanRemoveOfferIds = useCallback(() => {
    dispatch({ type: ActionTypes.offersDeleteSuccess });
  }, []);

  const handleRemoveOffer = useCallback(() => {
    dispatch({ type: ActionTypes.loadingPage, value: true });
    customOffersService.deleteCustomOffers(deleteOfferIds).subscribe({
      next: () => {
        handleCleanRemoveOfferIds();
        handleUpdateList();
        Toast.show('Oferta deletada com sucesso');
      },
      error: () => {
        Toast.error('Erro ao deletar a oferta');
      }
    });
  }, [deleteOfferIds, handleCleanRemoveOfferIds, handleUpdateList]);

  const handleOpenModal = (open?: boolean) => {
    if (!open) {
      return dispatch({ type: ActionTypes.openModalOffer, value: open });
    }

    return !eventData?.eventTickets.length
      ? Toast.error('Não existem ingressos cadastrados')
      : dispatch({ type: ActionTypes.openModalOffer, value: open });
  };

  const handleGenerateLinkOffer = useCallback((offerId: string) => {
    dispatch({ type: ActionTypes.loadingLinkOfferId, value: offerId });
    customOffersService.createCustomOffersLink(offerId).subscribe({
      next: ({ data }) => {
        dispatch({ type: ActionTypes.generateLinkOffer, value: data.payment_url });
      },
      error: ({ data: err }) => {
        Toast.error(err.data.error.msg);
        dispatch({ type: ActionTypes.loadingLinkOfferId, value: '' });
      }
    });
  }, []);

  const handleOpenModalDelete = useCallback((offerIds: DeleteOffer) => {
    dispatch({ type: ActionTypes.offersDelete, value: offerIds });
  }, []);

  return (
    <S.Container>
      <ReactPlaceholder customPlaceholder={<CustomLoader />} ready={isShown}>
        {isShown && (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <PageTitle
                title='Ofertas personalizadas'
                subtitle='Faça uma oferta personalizada e exclusiva para seus clientes'
                onIconClick={openTour}
              />
              <Button onClick={() => handleOpenModal(true)} size='large' type='primary' icon={<PlusOutlined />}>
                Adicionar oferta
              </Button>
            </div>

            <p className='custom-offer-description'>
              Com a oferta personalizada é possível disponibilizar uma oportunidade de compra única para seu cliente. O
              link gerado é válido para apenas 1 compra, não podendo utilizá-lo novamente.
            </p>
            <p className='discount-coupon-description'>
              Caso sua estratégia seja oferecer desconto para mais de um cliente, utilize o&nbsp;
              <a
                href='https://ajuda.eduzz.com/hc/pt-br/articles/1260807644469-Como-criar-um-cupom-de-desconto-'
                target='_blank'
                rel='noopener noreferrer'
                className='discount-coupon-link'
              >
                cupom de desconto
              </a>
              .
            </p>
            <p className='custom-offer-tutorial'>Para saber mais sobre a oferta personalizada</p>

            <a
              href='https://ajuda.eduzz.com/hc/pt-br/articles/4409146374939-Como-cadastrar-uma-Oferta-Personalizada-no-meu-evento-Blinket'
              target='_blank'
              rel='noopener noreferrer'
              className='custom-offer-link'
            >
              Veja um tutorial que preparamos pra você <ArrowRightOutlined />
            </a>
            <Divider />

            {offers.map(offer => {
              return (
                <OffersTickets
                  loadingDelete={loadingPage}
                  loadingLink={loadingLinkOfferId && loadingLinkOfferId === offer.id}
                  disableButton={(loadingLinkOfferId && loadingLinkOfferId !== offer.id) || loadingPage}
                  handleRemoveOffer={handleOpenModalDelete}
                  handleGenerateLinkOffer={handleGenerateLinkOffer}
                  key={offer.id}
                  {...offer}
                />
              );
            })}

            <OffersContext.Provider value={{ tickets: eventData.eventTickets, eventId }}>
              <ModalOffers
                onSubmitForm={handleSubmitForm}
                onClose={() => dispatch({ type: ActionTypes.openModalOffer, value: false })}
                open={openModalOffer}
                key={String(openModalOffer)}
              />
            </OffersContext.Provider>

            <ModalLink
              open={!!generateLinkOffer}
              onClose={() => dispatch({ type: ActionTypes.generateLinkOffer, value: '' })}
              offerLink={generateLinkOffer}
            />

            <ModalDelete
              onClose={handleCleanRemoveOfferIds}
              open={!!deleteOfferIds.offerPackId}
              handleRemoveOffer={handleRemoveOffer}
            />
          </>
        )}
      </ReactPlaceholder>
      {isShown && <Tour steps={steps} open={showTour} onClose={closeTour} />}
    </S.Container>
  );
};

export default CustomOffers;
