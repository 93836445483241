import { Table, Typography } from 'components/Shared/Antd';

import { EventTicketsProps } from './types';

const columns = [
  {
    title: 'Lote',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Início das vendas',
    dataIndex: 'startSale',
    key: 'startSale'
  },
  {
    title: 'Término das vendas',
    dataIndex: 'endSale',
    key: 'endSale'
  },
  {
    title: 'Qtd. disponível',
    dataIndex: 'available',
    key: 'available'
  },
  {
    title: 'Vendas',
    dataIndex: 'sold',
    key: 'sold'
  },
  {
    title: 'Valor',
    dataIndex: 'price',
    key: 'price'
  }
];

const EventTickets = ({ tickets }: EventTicketsProps) => {
  const newTickets = tickets?.map(ticket => ({
    ...ticket,
    lot: ticket.lot.map((lot, index) => ({
      ...lot,
      name: lot.name ? lot.name : index + 1,
      available: ticket.is_unlimited === 1 ? 'Ilimitado' : lot.available + '/' + lot.quantity,
      price: 'R$ ' + lot.price
    }))
  }));

  return (
    <>
      {newTickets?.map(ticket => {
        return (
          <div key={ticket.id} style={{ marginBottom: '30px' }}>
            <Typography.Text strong>{ticket.name}</Typography.Text>
            <Table pagination={false} key={ticket.id} dataSource={ticket.lot} columns={columns} />
          </div>
        );
      })}
    </>
  );
};

export default EventTickets;
