import * as React from 'react';

import { Tabs } from 'antd';
import Title from 'antd/es/typography/Title';
import AttendanceList from 'components/Pages/Admin/AttendanceList';
import { EventDetail, EventDetailProvider } from 'components/Pages/Admin/TabsMyEvents/context/EventDetail';
import useCustomObservable from 'hooks/useCustomObservable';
import BasicData from 'Pages/MyEvents/BasicData';
import CustomOffers from 'Pages/MyEvents/CustomOffers';
import CustomTemplate from 'Pages/MyEvents/CustomTemplate';
import EventCart from 'Pages/MyEvents/EventCart';
import EventPage from 'Pages/MyEvents/EventPage';
import EventPanel from 'Pages/MyEvents/EventPanel';
import SaleLinksPanel from 'Pages/MyEvents/SaleLinksPanel';
import { useParams, useHistory } from 'react-router-dom';
import eventService from 'services/event';
import styled from 'styled-components';

const { TabPane } = Tabs;

const tabs = [
  {
    key: 'basic-data',
    label: 'Resumo',
    children: <BasicData />
  },
  {
    key: 'sale-links',
    label: 'Links',
    children: <SaleLinksPanel />
  },
  {
    key: 'presence-list',
    label: 'Participantes'
  },
  {
    key: 'event-panel',
    label: 'Painel',
    children: <EventPanel />
  },
  {
    key: 'event-page',
    label: 'Página do Evento',
    children: <EventPage />,
    subRoutes: [
      {
        key: 'template-basic',
        children: <CustomTemplate />
      }
    ]
  },
  {
    key: 'offers',
    label: 'Ofertas',
    children: <CustomOffers />
  },
  {
    key: 'cart',
    label: 'Carrinho de compras',
    children: <EventCart />
  }
];

type Params = {
  id: string;
  page: string;
  subPage: string;
};

const TabsMyEvents = ({ ...rest }) => {
  const { page, subPage, id } = useParams<Params>();
  const history = useHistory();

  const onTabChange = (key: string) => {
    if (subPage) {
      history.push(`/my-events/${id}/${key}`);
      return;
    }
    history.push(key);
  };

  const attendanceProps = window['attendanceProps']['attendanceProps'];

  const {
    isOpenAttendanceDetail,
    isOpenAttendanceList,
    cliCodSelected,
    showAttendanceListTour,
    handleOpenAttendanceDetail,
    handleCloseAttendanceDetail,
    handleOpenAttendanceListTour,
    handleCloseAttendanceListTour
  } = attendanceProps;

  const classes = window['attendanceProps']['classes'];

  const { value: data, loading } = useCustomObservable<EventDetail, void, void>(
    () => eventService.allDetails(id),
    null
  );

  if (loading) {
    return null;
  }

  return (
    <EventDetailProvider data={data} loading={loading}>
      <div style={{ marginBottom: '8px' }}>
        <Title level={4}> {data?.event.title} </Title>
      </div>
      <Tabs activeKey={page} onChange={onTabChange} {...rest}>
        {tabs.map(({ key, label, children, subRoutes }) => {
          if (key === 'presence-list') {
            return (
              <TabPane forceRender tab={label} key={key}>
                <AttendanceList
                  classes={classes}
                  isOpenAttendanceDetail={isOpenAttendanceDetail}
                  isOpenAttendanceList={isOpenAttendanceList}
                  cliCodSelected={cliCodSelected}
                  showAttendanceListTour={showAttendanceListTour}
                  handleOpenAttendanceDetail={handleOpenAttendanceDetail}
                  handleCloseAttendanceDetail={handleCloseAttendanceDetail}
                  handleOpenAttendanceListTour={handleOpenAttendanceListTour}
                  handleCloseAttendanceListTour={handleCloseAttendanceListTour}
                />
              </TabPane>
            );
          }
          if (subRoutes && subPage) {
            const subRoute = subRoutes.find(item => item.key === subPage);
            return (
              <TabPane forceRender tab={label} key={key}>
                {subRoute['children']}
              </TabPane>
            );
          }

          if (key === 'event-page' && data?.event.is_live === 1) {
            return null;
          }

          return (
            <TabPane forceRender tab={label} key={key}>
              {children}
            </TabPane>
          );
        })}
      </Tabs>
    </EventDetailProvider>
  );
};

const StyledTabsMyEvents = styled(TabsMyEvents)`
  .ant-tabs-nav {
    margin-bottom: 32px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000000 !important;
    font-weight: 500;
  }
`;

export default StyledTabsMyEvents;
