import { useState, useEffect } from 'react';

import { LotInfos } from 'components/Presentational/EventTickets/types';
import useInterval from 'hooks/useInterval';
import { IEvent, ILoteDetail } from 'interfaces/event';
import eventService from 'services/event';

import { formatDateToPtBr } from './helpers';

const isFinishedCreatingCntCods = (eventDetail: IEvent) => {
  return eventDetail.tickets.every(ticket => {
    const lotHasAllCntCods = ticket.lot.every(lot => lot.cnt_cod);
    if (ticket.cnt_cod == '0') {
      return false;
    }
    if (ticket.cnt_cod && lotHasAllCntCods) {
      return true;
    }
    if (!ticket.cnt_cod && lotHasAllCntCods) {
      return true;
    }
    return false;
  });
};

const useUpdateTicketListUntilAllCntCodsAreCreated = (initialEventDetail: IEvent, id: string) => {
  const [updatedEventDetail, setUpdatedEventDetail] = useState<IEvent>();

  useEffect(() => {
    setUpdatedEventDetail(initialEventDetail);
  }, [initialEventDetail]);

  useInterval(() => {
    if (!updatedEventDetail) return;
    if (isFinishedCreatingCntCods(updatedEventDetail)) return;

    eventService.details<IEvent>(id).subscribe({
      next: eventDetails => {
        setUpdatedEventDetail(eventDetails);
      }
    });
  }, 5000);

  return { eventTicketsList: mapEventDetailToTicketList(updatedEventDetail) };
};

const mapEventDetailToTicketList = (data: IEvent) => {
  const mapLotItems = (lot: ILoteDetail): LotInfos => {
    return {
      lot: lot.id,
      name: lot.name,
      available: lot.quantity_available,
      isActive: Boolean(lot.is_active),
      sold: lot.quantity_sold,
      price: lot.value,
      endSale: formatDateToPtBr(lot.end_sale, 'dd/MM/yyyy'),
      startSale: formatDateToPtBr(lot.start_sale, 'dd/MM/yyyy'),
      cnt_cod: lot.cnt_cod,
      cnt_hash: lot.cnt_hash,
      quantity: lot.quantity
    };
  };

  return data?.tickets.map(item => ({
    name: item.name,
    lot: item.lot.map(mapLotItems),
    cnt_cod: item.cnt_cod,
    cnt_hash: item.cnt_hash,
    id: item.id,
    is_unlimited: item.is_unlimited,
    isActive: Boolean(item.is_active)
  }));
};

export default useUpdateTicketListUntilAllCntCodsAreCreated;
